export default {
  props: {
    action: {
      type: String,
      require: false,
      default: "add",
    },
    value: {
      type: Boolean,
      require: true,
    },
    data: {
      type: Object,
      require: false,
      default: {},
    },
    parentInfo: {
      type: Object,
      require: false,
      default: {},
    },
    parentId: {
      type: String,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      errorsValidated: {},
    };
  },
  computed: {
    isActive: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submitForm() {
      console.log(this.formData);
    },
  },
};
