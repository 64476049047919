<template>
  <v-card style="overflow-y: auto; max-height: 600px">
    <v-card-title class="form__title py-0">
      {{ action === "add" ? "Добавление" : "Редактирование" }} {{ title }}
    </v-card-title>

    <v-container>
      <v-card-text class="pa-0">
        <slot />
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer />
        <v-btn
          tile
          elevation="0"
          class="white--text"
          color="green"
          @click="$emit('submit')"
          >Добавить</v-btn
        >
        <v-btn
          tile
          elevation="0"
          class="white--text"
          color="lightBlue"
          @click="$emit('cancel')"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "FormPlacementLayout",
  props: {
    sizeWindow: {
      type: String,
      default: "big",
    },
    action: {
      type: String,
      default: "add",
    },
    title: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      activeDialog: this.active,
    };
  },
  computed: {},
};
</script>

<style lang="scss">
.form__item-text {
  min-width: 130px;
}
/*
.form__title {
  background-color: var(--color-darkGray);
  font-size: 12px !important;
} */
</style>
