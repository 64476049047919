<template>
  <section>
    <div class="pa-3">
      <h1 class="page-title mb-3">
        Список всех устройств
      </h1>
      <div>
        <v-row no-gutters class="mb-3">
          <v-col>
            <div style="width: 450px">
              <v-text-field
                v-model="search"
                dense
                hide-details
                outlined
                color="blue"
                label="Поиск"
                class="base-text-field"
              >
                <template v-slot:append-outer>
                  <v-icon color="blue">
                    mdi-magnify
                  </v-icon>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-btn
          class="text-capitalize"
          color="green"
          elevation="0"
          height="29"
          tile
          style="color: #fefefe"
          @click="isEditDialog = !isEditDialog"
        >
          <input ref="uploader" accept="image/*" class="d-none" type="file" />
          Добавить устройство
        </v-btn>
      </div>
    </div>

    <!-- <v-card :height="windowHeight" elevation="0" tile> -->
    <v-data-table
      dense
      fixed-header
      class="base-table"
      :footer-props="{
        'items-per-page-text': 'Записей на странице:',
        'items-per-page-all-text': 'Все',
        'items-per-page-options': [25, 50, 100],
      }"
      :headers="headers"
      :height="windowHeight - 220"
      :items="items"
      :search="search"
      no-data-text="Нет данных для отображения"
      @click:row="clickRow"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <!-- </v-card> -->

    <v-dialog v-model="isConfirmationDialog" width="400px">
      <v-card tile>
        <v-card-title> Внимание </v-card-title>
        <v-card-text>
          {{ confirmationDialogText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-capitalize"
            color="#5FB760"
            elevation="0"
            height="29"
            style="font-size: 12px"
            tile
            @click="submitDelete"
          >
            Да
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="#5FB760"
            elevation="0"
            height="29"
            style="font-size: 12px"
            tile
            @click="closeConfirmDialog"
          >
            Отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <dialog-add-edit-main
      v-if="isEditDialog"
      v-model="isEditDialog"
      action="add"
      typeDialog="device"
      :isEditTypeDevice="true"
      :dataItem="formData"
      @onSubmitForm="handleSubmitForm"
    ></dialog-add-edit-main> -->

    <form-device
      @onSubmitForm="handleSubmitForm"
      action="add"
      v-if="isEditDialog"
      :data="null"
      :parentInfo="null"
      v-model="isEditDialog"
    ></form-device>
    <v-snackbar v-model="isInfoDialog" top color="white">
      <span class="black--text">
        {{ infoDialogText }}
      </span>
    </v-snackbar>
  </section>
</template>

<script>
// import DialogAddEditMain from "../components/Dialogs/DialogsAddEdit/DialogAddEditMain.vue";
import FormDevice from "@/components/Dialogs/DialogsAddEdit/FormDevice.vue";

export default {
  components: { FormDevice },
  data() {
    return {
      isEditDialog: false,
      search: "",
      headers: [
        { text: "Расположение", value: "location" },
        { text: "Тип", value: "type" },
        { text: "SN", value: "serial" },
        { text: "Наименования", value: "name" },
        { text: "MAC", value: "mac" },
        { text: "Профиль", value: "profile.name" },
        { text: "Прошивка", value: "settings.firmware" },
        { text: "", value: "actions", sortable: false },
      ],
      isConfirmationDialog: false,
      confirmationDialogText: "",
      infoDialogText: "",
      isInfoDialog: false,
      formData: {},
    };
  },
  mounted() {
    this.$store.dispatch("fetchListDevices");
  },
  computed: {
    windowHeight() {
      return window.innerHeight;
    },
    items() {
      return this.$store.getters.getListDevices;
    },
  },
  methods: {
    clickRow(item) {
      this.$router.push(`/Devices/${item.id}`);
    },
    handleSubmitForm(response) {
      console.log("submit form ", response);
      this.$store.dispatch("fetchListDevices");
      this.infoDialogText = "Устройство успешно создано";
      this.isInfoDialog = true;
    },
    deleteItem(item) {
      this.formData = { ...item };
      this.confirmationDialogText = `Вы действительно хотите удалить ${item.serial}?`;
      this.isConfirmationDialog = true;
    },
    closeConfirmDialog() {
      this.isConfirmationDialog = false;
    },
    submitDelete() {
      this.$store
        .dispatch("deleteItem", {
          type: "device",
          id: this.formData.id,
        })
        .then(() => {
          this.isConfirmationDialog = false;
          this.infoDialogText = "Устройство успешно удалено";
          this.$store.dispatch("fetchListDevices");
        })
        .catch((err) => {
          this.isConfirmationDialog = false;
          this.infoDialogText = err;
          this.$store.dispatch("fetchListDevices");
        })
        .finally(() => {
          this.isInfoDialog = true;
        });
      this.formData = undefined;
    },
    handleFileImport() {
      this.$refs.uploader.click();
    },
  },
};
</script>
