<template>
  <v-dialog v-model="isActive" :width="400" persistent>
    <form-placement-layout
      sizeWindow="device"
      title="устройства"
      @submit="submitForm"
      @cancel="isActive = false"
      :action="action"
    >
      <error-info :error="'type' in errorsValidated">
        {{ errorsValidated.type }}
      </error-info>
      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left ">Тип</span>
        <v-text-field
          dense
          hide-details
          outlined
          v-if="parentInfo"
          :disabled="true"
          :value="typeDevice"
          class="base-text-field"
        />
        <v-select
          dense
          hide-details
          outlined
          v-if="!parentInfo"
          :disabled="!!parentInfo"
          :items="[
            { name: 'Планшет', value: 'TABLET' },
            { name: 'Датч. Движения', value: 'SENSOR' },
            { name: 'Оборудование', value: 'DEVICE' },
          ]"
          item-text="name"
          item-value="value"
          v-model="formData.type"
          class="base-select"
        >
        </v-select>
      </div>
      <error-info :error="'name' in errorsValidated">
        {{ errorsValidated.name }}
      </error-info>
      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left">
          Наименование
        </span>
        <v-text-field
          dense
          hide-details
          outlined
          autofocus
          v-model="formData.name"
          class="base-text-field"
        />
      </div>

      <error-info :error="'serial' in errorsValidated">
        {{ errorsValidated.serial }}
      </error-info>
      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left">Серийный номер</span>
        <v-text-field
          dense
          hide-details
          outlined
          v-model="formData.serial"
          class="base-text-field"
        />
      </div>

      <error-info :error="'mac' in errorsValidated">
        {{ errorsValidated.mac }}
      </error-info>
      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left">MAC</span>
        <v-text-field
          dense
          hide-details
          outlined
          v-model="formData.mac"
          class="base-text-field"
        />
      </div>

      <error-info :error="'profile' in errorsValidated">
        {{ errorsValidated.profile }}
      </error-info>
      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left">Профиль</span>
        <v-select
          dense
          hide-details
          outlined
          class="base-select"
          :disabled="action === 'edit'"
          v-model="formData.profile_id"
          append-icon=""
          :items="profiles"
          item-text="name"
          item-value="id"
        />
      </div>
    </form-placement-layout>
  </v-dialog>
</template>

<script>
import formPlacement from "@/mixins/formPlacement.js";

import ErrorInfo from "../ErrorInfo.vue";
import FormPlacementLayout from "./FormPlacementLayout.vue";

import { mapActions } from "vuex";

export default {
  name: "FormDevice",
  components: { FormPlacementLayout, ErrorInfo },
  mixins: [formPlacement],
  props: {},
  data() {
    return {
      seceltedType: null,
      isEditType: false,
    };
  },
  mounted() {
    this.init();
    this.$store.dispatch("fetchProfiles");
  },
  methods: {
    ...mapActions({
      add: "createDevice",
      edit: "updateDevice",
    }),
    init() {
      if (this.data) {
        this.formData = { ...this.data };
      }
    },
    submitForm() {
      if (!this.validate()) return;
      const fd = new FormData();
      const payload = JSON.stringify({
        ...(this.parentInfo
          ? { device_type: this.parentInfo.type.toUpperCase() }
          : { device_type: this.formData.type }),
        name: this.formData.name,
        serial: this.formData.serial,
        mac: this.formData.mac,
        profile_id: this.formData.profile_id,
        ...(this.action === "add" ? { parent_id: this.parentId } : ""),
      });
      fd.append("data", payload);
      if (this.action === "add") {
        this.add(payload)
          .then((res) => {
            this.$emit("onSubmitForm", res.data.data);
            this.isActive = false;
          })
          .catch((err) => {
            alert(err);
          });
      }
      if (this.action === "edit") {
        this.edit({ id: this.formData.id, payload: payload })
          .then((res) => {
            this.$emit("onSubmitForm", res.data.data);
            this.isActive = false;
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    validate() {
      this.errorsValidated = {};
      const regName = /^.{3,127}$/;
      if (!regName.test(this.formData.name) || !this.formData.name) {
        this.errorsValidated.name =
          "Наименование должно быть от 3 до 127 символов";
      }

      if (!this.formData.serial) {
        this.errorsValidated.serial =
          "Поле серийный номер должно быть заполнено";
      }

      const regMac = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
      if (!this.formData.mac || !regMac.test(this.formData.mac)) {
        this.errorsValidated.mac =
          "Неверный формат mac адреса пример:(00:00:5e:00:53:af)";
      }

      if (!this.formData.profile_id) {
        this.errorsValidated.profile = "Выберите профиль";
      }

      if (Object.keys(this.errorsValidated).length == 0) return true;
      return false;
    },
  },
  computed: {
    typeDevice() {
      if (!this.parentInfo) return;
      let types = {
        sensor: "Датчик движ.",
        tablet: "Планшет",
      };
      return types[this.parentInfo.type];
    },
    profiles() {
      if (!this.parentInfo) {
        return this.$store.getters.getProfilesDataArray.filter((item) => {
          if (item.device_type === this.formData.type) return item;
        });
      }
      if (this.parentInfo.type === "tablet") {
        return this.$store.getters.getProfilesDataArray.filter((item) => {
          if (item.device_type === "TABLET") return item;
        });
      }
      if (this.parentInfo.type === "sensor") {
        return this.$store.getters.getProfilesDataArray.filter((item) => {
          if (item.device_type === "SENSOR") return item;
        });
      }
      return [];
    },
  },
};
</script>
